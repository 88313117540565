import { Router, RouteObject } from "./router";
import { IndexPage } from "../pages/indexPage";
import { ChatRoomPage } from "../pages/chatRoom";
import { c, addChildren } from "./utils";
import { WebSocketClient, WS } from "./websocket";

/**
 * Startup routine for SPA
 */

const body = document.getElementsByTagName("body")[0];
const masterContainer = c("div", { className: "masterContainer" });
const container = c("div", { className: "appContainer flex" });

export const router = new Router(container);

function addRoutes(routeObjects: RouteObject[]) {
  for (const routeObj of routeObjects) {
    router.addRoute(routeObj);
  }
}

const routeObjects = [
  { routePath: "/", routePage: IndexPage } as RouteObject,
  { routePath: "/room", routePage: ChatRoomPage } as RouteObject,
];

addRoutes(routeObjects);

/**
 * Common areas (such as nav bar) which are
 * not within the router container
 */
const navBar = c("div", { className: "navBar flex" });
const logo = c("h3", { className: "logo", innerText: "Fence" });
const twitter = c("a", { className: "twitterLink", innerText: "🚀🚀🚀" });

// Callbacks
logo.addEventListener(
  "click",
  async (e) => {
    e.preventDefault();
    await logout("dummyuser");
    terminateConnections();
    router.navigateTo("/");
  },
  {
    once: false,
  }
);

function terminateConnections() {
  if (window.fenceAppState.webSocketConnection) {
    window.fenceAppState.webSocketConnection.close();
    delete window.fenceAppState.webSocketConnection;
  }

  if (window.fenceAppState.webSocketClient) {
    delete window.fenceAppState.webSocketClient;
  }
}

async function logout(nickName: string): Promise<boolean> {
  const host = window.location.hostname;
  const port = window.location.port;
  const request = await fetch(`https://${host}:${port}/api/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      nickname: `${nickName}`,
    }),
  });

  return Promise.resolve(request.ok);
}

addChildren(navBar, [logo, twitter]);

/**
 * Adding all elements to the body of our
 * page
 */
addChildren(masterContainer, [navBar, container]);
addChildren(body, [masterContainer]);

/**
 * Render start page (join chat room)
 */
router.navigateTo("/");

/**
 * State container for the Fence app,
 * stored on the global Window object.
 */
declare global {
  export interface Window {
    fenceAppState: any;
  }
}

/**
 * Global window objects
 */
window.fenceAppState = {} as FenceAppState;
window.fenceAppState.router = router as Router;
// window.fenceAppState.webSocketClient = undefined;

interface FenceAppState {
  webSocketClient: WebSocketClient;
  webSocketConnection: WS;
  router: Router;
  userLeft: boolean;
  unreadCount: number;
}
