import { router } from "../client";
import { addChildren, c, event } from "../client/utils";
import { WebSocketClient, WS } from "../client/websocket";
import { BasePage } from "./Page";

export class IndexPage extends BasePage {
  constructor(routePath: string, containerElement: HTMLElement) {
    super(routePath, containerElement);
  }

  async pre() {
    // Check if used is already logged in, route to
    // chat room directly if so.
    const storedUserName = window.localStorage["userName"];
    if (storedUserName === "undefined" || !storedUserName) {
      return;
    }

    const tokenValid = await checkToken(storedUserName);
    console.log("Token valid: ", tokenValid);
    if (!tokenValid) {
      return;
    }

    const nicknameAvailable = await checkNickname(storedUserName);
    console.log("Nickname available: ", nicknameAvailable);
    if (!nicknameAvailable) {
      return;
    }

    // We're good - set up comms.
    const webSocketClient = await getWebSocket();
    const login = (await webSocketClient.registerNewUser(
      storedUserName
    )) as any;
    if (login.status === "success") {
      router.navigateTo("/room");
      return true;
    }
  }

  render() {
    // Jumbo
    const introTitle = c("h2", {
      className: "introTitle",
      innerText: "Old-skool chat with modern tech - for friends and family ✨",
    });

    // Join form
    const joinFormContainer = c("div", { className: "joinSectionContainer" });
    const button = c("button", {
      className: "joinButton",
      textContent: "Connect",
    });

    // Status indicator
    const statusIndicator = c("p", {
      className: "statusIndicator",
      innerText: "",
    });

    const fieldsContainer = genFields(button);
    addChildren(joinFormContainer, [fieldsContainer, statusIndicator, button]);
    addChildren(this.container, [introTitle, joinFormContainer]);
    this.container.appendChild(joinFormContainer);
  }
}

function updateStatusIndicator(newStatus: string) {
  const statusIndicator = document.querySelector(".statusIndicator");
  statusIndicator.innerHTML = newStatus;
}

function genFields(button) {
  // Nickname
  const nickNameField = c("div", { className: "nickNameField flex" });
  const userNamePromptLabel = c("label", {
    for: "userName",
    innerHTML: "Nickname",
    className: "formLabel",
  });

  const userNamePrompt = c("input", {
    id: "userNamePrompt",
    type: "text",
    required: "required",
    value: window.localStorage["userName"] ?? null,
    className: "fieldInput",
  });
  addChildren(nickNameField, [userNamePromptLabel, userNamePrompt]);

  // Password field
  const passwordField = c("div", { className: "passwordField flex" });
  const passwordLabel = c("label", {
    for: "password",
    innerHTML: "Super Secret Word",
    className: "formLabel",
  });

  const passwordInput = c("input", {
    id: "passwordInput",
    type: "password",
    className: "fieldInput",
  });
  addChildren(passwordField, [passwordLabel, passwordInput]);

  // Color field
  const colorField = c("div", { className: "colorField flex" });
  const colorPromptLabel = c("label", {
    for: "colorPicker",
    innerHTML: "Select color",
    className: "formLabel",
  });

  const colorInput = c("input", {
    id: "colorInput",
    type: "color",
    className: "fieldInput",
  });
  addChildren(colorField, [colorPromptLabel, colorInput]);

  // Final container
  const fieldsContainer = c("div", { className: "fieldsContainer flex" });
  addChildren(fieldsContainer, [nickNameField, passwordField, colorField]);

  // Events
  event(button, "click", loginToChannel, { once: false }, [
    userNamePrompt,
    passwordInput,
  ]);

  event(
    passwordInput,
    "keyup",
    (e: Event, htmlElements) => {
      const ev = e as KeyboardEvent;
      if (ev.code === "Enter") {
        e.preventDefault();
        loginToChannel(e, htmlElements);
      }
    },
    { once: false },
    [userNamePrompt, passwordInput]
  );

  return fieldsContainer;
}

function storeUserNameLocally(userName) {
  let storedUserName = window.localStorage["userName"];
  if (storedUserName !== userName) {
    window.localStorage["userName"] = userName;
  }

  if (userName === "") {
    alert("Please type a user name");
    return;
  }

  return userName;
}

async function loginToChannel(e: Event, htmlElements) {
  // Disable button whilst authenticating
  const button = document.querySelector(".joinButton") as HTMLButtonElement;
  button.disabled = true;

  // Main login routine
  const [userNamePrompt, passwordInput] = htmlElements;
  let userName = storeUserNameLocally(userNamePrompt.value);
  const password = passwordInput.value;

  const isAuthenticated = await authenticateUser(userName, password);
  if (!isAuthenticated) {
    updateStatusIndicator("Are you sure you're from around here? 🛑");
    button.disabled = false;
    return;
  }

  const nicknameAvailable = await checkNickname(userName);
  if (!nicknameAvailable) {
    updateStatusIndicator("Username taken. 🤷‍♂️");
    button.disabled = false;
    return;
  }

  // Set up WebSocket connection and client if authenticated
  let webSocketClient: WebSocketClient = await getWebSocket();

  const login = (await webSocketClient.registerNewUser(userName)) as any;
  if (login.status === "success") {
    router.navigateTo("/room");
  } else {
    // Should not hit this code anymore
    updateStatusIndicator("Username taken. 🤷‍♂️");
    button.disabled = false;
  }
}

async function getWebSocket() {
  // Set up WebSocket connection and client if authenticated
  let webSocketClient: WebSocketClient = window.fenceAppState.webSocketClient;
  if (!webSocketClient) {
    webSocketClient = await setupWebSocketClient();
    // Make sure it's available on the app state object for other pages to use
    window.fenceAppState.webSocketClient = webSocketClient as WebSocketClient;
  }
  return webSocketClient;
}

async function authenticateUser(
  userName: string,
  password: string
): Promise<boolean> {
  const host = window.location.hostname;
  const port = window.location.port;
  const loginAttempt = await fetch(`https://${host}:${port}/api/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      nickname: `${userName}`,
      password: `${password}`,
    }),
  });

  return Promise.resolve(loginAttempt.ok);
}

async function checkNickname(nickName: string): Promise<boolean> {
  const host = window.location.hostname;
  const port = window.location.port;
  const request = await fetch(`https://${host}:${port}/api/usercheck`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      nickname: `${nickName}`,
    }),
  });

  return Promise.resolve(request.ok);
}

async function checkToken(nickName: string): Promise<boolean> {
  const host = window.location.hostname;
  const port = window.location.port;
  const request = await fetch(`https://${host}:${port}/api/tokencheck`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      nickname: `${nickName}`,
    }),
  });

  return Promise.resolve(request.ok);
}

/**
 * Retrieves an instance of the global WebSocket
 * connection and creates a communications broker
 * instance around it
 */
async function setupWebSocketClient() {
  let webSocketConnection = window.fenceAppState.webSocketConnection;
  if (!webSocketConnection) {
    webSocketConnection = await WS.createWebSocket(
      window.location.hostname,
      window.location.port
    );
    window.fenceAppState.webSocketConnection = webSocketConnection;
  }

  return new WebSocketClient(webSocketConnection);
}
