/**
 * Contains a static method to create a
 * WebSocket connection to a server.
 */
export class WS {
  static async createWebSocket(host: string, port: string) {
    return new Promise((resolve, reject) => {
      const webSocket = new WebSocket(`wss://${host}:${port}`);
      webSocket.onopen = () => {
        console.log("WebSocket connection opened successfully!");
        resolve(webSocket);
      };

      webSocket.onerror = (error) => {
        console.log("WebSocket connection failed.");
        reject(error);
      };
    });
  }

  static webSocket: WebSocket;
}

/**
 * A connection broker between a WebSocket
 * instance and the application layer.
 * Provides a standardised messaging
 * interface.
 */
export class WebSocketClient {
  constructor(ws: WebSocket) {
    this.ws = ws;
    this.ws.onmessage = (msg) => this.processIncomingMessage(msg);
    this.callbacks = {};

    // Keealive ping logic
    this.registerCallback("pong", () => {
      console.log("...Pong!");
      clearTimeout(this.pingTimeout);
    });
    this.ws.onclose = (e: CloseEvent) => {
      console.log("Websocket connection closed");
      clearTimeout(this.pingTimeout);
      clearInterval(this.pingInterval);
      const statusBarText = document.querySelector(
        ".statusBarText"
      ) as HTMLElement;
      if (statusBarText) {
        statusBarText.innerText = "Disconnected 🔴";
      }
    };

    // Kick off ping interval
    this.pingInterval = setInterval(() => {
      this.sendPing();
    }, 30000 + 1000);
  }

  pingTimeoutFunc() {
    return setTimeout(() => {
      console.log("Timeout reset");
      this.ws.close();
    }, 30000 + 1000);
  }

  /**
   * Registers callbacks for incoming messages from the
   * WebSocket server.
   * @param action message type coming from the server
   * @param callback function to handle the event
   */
  registerCallback(action: string, callback: () => void) {
    this.callbacks[action] = callback;
  }

  /**
   * Default method to parse and print
   * incoming messages from WebSocket server.
   * @param msg incoming message from WebSocket server
   */
  processIncomingMessage(msg: MessageEvent<any>) {
    const messageData = JSON.parse(msg.data);
    const { type, ...rest } = messageData;
    switch (type) {
      case "loginMessage":
        this.loginResolveFunction(rest);
        break;
      default:
        if (this.callbacks[type]) {
          this.callbacks[type](rest);
        }
        break;
    }
  }

  /**
   * Periodic ping messages to the backend to check
   * the WebSocket connection is alive.
   */
  sendPing() {
    console.log("Ping!");
    const message = WebSocketClient.createMessageBody(
      MessageType.Ping,
      undefined
    );
    this.sendMessage(message);
  }

  /**
   * Send a request to retrieve the user list
   * in the channel.
   */
  getUserList() {
    const message = WebSocketClient.createMessageBody(
      MessageType.UserList,
      undefined
    );
    this.sendMessage(message);
  }

  /**
   * Send a new user registration message to
   * the WebSocket server.
   * @param userName nickname of user to register
   */
  registerNewUser(userName: string) {
    const message = WebSocketClient.createMessageBody(
      MessageType.RegisterNewUser,
      {
        userName,
      } as NewUserMessage
    );
    this.sendMessage(message);

    return new Promise((resolve, reject) => {
      this.loginResolveFunction = resolve;
    });
  }

  /**
   * Sends a text message to the common
   * chat channel.
   * @param msgText content of the message
   */
  BroadcastChannelMessage(messageText: string) {
    const message = WebSocketClient.createMessageBody(
      MessageType.ChannelMessage,
      {
        messageText,
      } as ChannelMessage
    );
    this.sendMessage(message);
  }

  sendMessage(msg: WebSocketMessage) {
    this.ws.send(JSON.stringify(msg));
  }

  /**
   * Creates a standardised message body
   * for communication with the WebSocket server.
   * @param type the message type (MessageType)
   * @param messageContent the content of the message (string)
   */
  static createMessageBody(
    type: MessageType,
    messageContent: NewUserMessage | ChannelMessage | undefined
  ): WebSocketMessage {
    return {
      type,
      messageContent,
      date: Date.now(),
    };
  }

  ws: WebSocket;
  loginResolveFunction: Function;
  callbacks: { [key: string]: Function };
  pingTimeout: NodeJS.Timeout;
  pingInterval: NodeJS.Timeout;
}

type WebSocketMessage = {
  type: MessageType;
  messageContent: NewUserMessage | ChannelMessage | undefined;
  date: number;
};

interface OutgoingMessage {
  date: number;
}

interface NewUserMessage extends OutgoingMessage {
  userName: string;
}

interface ChannelMessage extends OutgoingMessage {
  messageText: string;
}

enum MessageType {
  RegisterNewUser,
  ChannelMessage,
  UserList,
  Ping,
}
