import { addChildren, c, event } from "../client/utils";
import { BasePage } from "./Page";

export class ChatRoomPage extends BasePage {
  constructor(routePath: string, containerElement: HTMLElement) {
    super(routePath, containerElement);
  }

  render() {
    // DOM Elements
    const grid = c("div", { className: "grid" });
    const userList = c("div", {
      innerText: "userList",
      className: "userList darkGrid",
      id: "userList",
    });
    const channel = c("div", {
      innerText: "",
      className: "channel darkGrid",
      id: "channelFeed",
    });

    // Status Bar
    const statusBar = c("div", { className: "statusBar" });
    const statusBarText = c("p", {
      className: "statusBarText",
      innerText: "Connected 🟢",
    });
    addChildren(statusBar, [statusBarText]);

    // Chat typing box
    const chatBox = createChatBox();

    // Event listeners
    document.addEventListener("mouseleave", logUserLeft);
    document.addEventListener("mouseenter", logUserEntered);
    event(channel, "click", replyTo, { once: false }, [chatBox]);

    // Registering callbacks
    window.fenceAppState.webSocketClient.registerCallback(
      "userList",
      updateUserList
    );
    window.fenceAppState.webSocketClient.registerCallback(
      "channelMessage",
      handleChannelMessage
    );

    // Request existing user list, if any
    window.fenceAppState.webSocketClient.getUserList();

    // Appending elements
    addChildren(grid, [statusBar, userList, channel, chatBox]);
    this.container.appendChild(grid);

    // Focus on the chatBox to save the user an extra click.
    chatBox.focus();
  }
}

function replyTo(e: MouseEvent, chatBox): void {
  // Bail if Ctrl/Command is pressed.
  if (e.ctrlKey) {
    return;
  }
  const sel = document.querySelector(".channelChatLine:hover");
  console.log(sel);
  const [timeSpan, userNameChat, messageChat] = Array.from(sel.children).slice(
    0,
    3
  ) as HTMLElement[];

  const replyToName = userNameChat.innerText;
  const quotedText = messageChat.innerHTML;
  const nameSpan = c("span", {
    className: "replyToName",
    innerText: `${replyToName} wrote:`,
  });
  const quotedTextSpan = c("span", {
    className: "quotedText",
    innerHTML: `${quotedText}<hr class="replyToDivider">`,
  });
  const replyToDivContainer = c("div", {
    className: "replyToContainer",
  });
  const replyToDiv = c("div", {
    className: "replyToMessageText",
  });
  const userTextReplyDiv = c("div", {
    innerHTML: "<br>",
  });

  addChildren(replyToDiv, [nameSpan, quotedTextSpan]);
  addChildren(replyToDivContainer, [replyToDiv, userTextReplyDiv]);

  chatBox[0].innerHTML = replyToDivContainer.innerHTML;

  // This is the quickest way to scroll the cursor
  // to the end of the input div
  chatBox[0].focus();
  document.execCommand("selectAll", false, null);
  // collapse selection to the end
  document.getSelection().collapseToEnd();
}

function logUserLeft(e: Event) {
  window.fenceAppState.userLeft = true;
  window.fenceAppState.unreadCount = 0;
  e.preventDefault();
}

function logUserEntered(e: Event) {
  window.fenceAppState.userLeft = false;
  document.title = "Fence";
  e.preventDefault();
}

function updateUserList(message) {
  const userList = message.userList;
  // Debug statement
  // console.log(userList);
  let userListHTML = document.querySelector("#userList");
  userListHTML.innerHTML = "";
  const userContainer = c("ul", {});
  userList.forEach((element) => {
    let item = c("li", {});
    item.innerText = element;
    userContainer.appendChild(item);
  });
  userListHTML.appendChild(userContainer);
}

function handleChannelMessage(message) {
  const { userName, messageText, date } = message;
  const dateObj = Intl.DateTimeFormat("en-GB", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  })
    .format(new Date(date))
    .split(/\/|,/);
  const timeObject = `${dateObj[2]}-${dateObj[1]}-${
    dateObj[0]
  }\n${dateObj[3].trim()}`;
  const channelFeed = document.querySelector("#channelFeed");
  const richText = c("div", { className: "channelChatLine" });
  const timeStampSpan = c("span", {
    className: "timeSpan",
    innerText: timeObject,
  });
  const userNameSpan = c("span", {
    className: "userNameChat",
    innerText: userName,
  });
  const messageChatSpan = c("span", {
    className: "messageChat",
    innerHTML: messageText,
  });
  const replyToArrowDiv = c("div", {
    className: "replyToDiv",
    innerHTML: `<svg version="1.1" id="replyTo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  width="27.361px" height="20px" viewBox="0 0 27.361 27.361"
  xml:space="preserve">
<g>
 <path d="M0,12.022l9.328-9.328v4.146h9.326c4.809,0,8.707,3.898,8.707,8.706v9.12c0-4.81-3.898-8.704-8.707-8.704H9.328v5.389
   L0,12.022z"/>
</g>
</svg>`,
  });
  addChildren(richText, [
    timeStampSpan,
    userNameSpan,
    messageChatSpan,
    replyToArrowDiv,
  ]);
  channelFeed.appendChild(richText);

  // Scroll down if needed
  if (channelFeed.scrollHeight > channelFeed.clientHeight) {
    channelFeed.scrollTop = channelFeed.scrollHeight - channelFeed.clientHeight;
  }

  // Log unread count if applicable
  if (window.fenceAppState.userLeft) {
    handleUnreadMessages();
  }
}

function handleUnreadMessages() {
  window.fenceAppState.unreadCount++;
  document.title = `Fence: (${window.fenceAppState.unreadCount})`;
}

function linkify(str: string): string {
  const linkText = str.match(/(?<=\(\/link\s)(.*?)(?=\))/)[0];
  const linkified = linkText.replace(
    /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim,
    (match, ...rest) => `<a href=${match}>${match}</a>`
  );
  return linkified;
}

function containsCommand(str: string): string[] | undefined {
  return str.match(/(?<=\(\/)(.*?)(?=\))/);
}

function processUserInput(str: string): string {
  let returnValue = str;
  while (containsCommand(returnValue)) {
    returnValue = returnValue.replace(/(\(\/)(.*?)(?=\))\W/, linkify);
  }

  returnValue = returnValue
    .replace(/\n/g, "<br>")
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">");

  return returnValue;
}

function createChatBox() {
  const channel = c("div", {
    contentEditable: true,
    className: "chatBox darkGrid",
  });
  event(channel, "keypress", (e: Event) => {
    const ev = e as KeyboardEvent;
    const target = ev.target as HTMLTextAreaElement;

    const value = processUserInput(target.innerHTML);

    if (ev.key === "Enter" && !ev.shiftKey) {
      ev.preventDefault();
      if (value.trim() === "") {
        return;
      }
      window.fenceAppState.webSocketClient.BroadcastChannelMessage(value);
      target.innerHTML = "";
    }
  });

  return channel;
}
